// eslint-disable-next-line import/no-cycle
import apiBackend from '@/service/api-insurance';

const initialState = () => ({
  zurs: -1,
  apiResponse: {
    status: '',
    value: null,
    message: '',
  },
  place: '',
  postalCode: '',
  street: '',
  houseNumber: '',
});

export default {
  namespaced: true,

  state: () => ({
    zurs: -1,
    apiResponse: {
      status: '',
      value: null,
      message: '',
    },
    place: '',
    postalCode: '',
    street: '',
    houseNumber: '',
  }),

  mutations: {
    SET_PLACE(state, place) {
      state.place = place;
    },
    SET_POSTAL_CODE(state, postalCode) {
      state.postalCode = postalCode;
    },
    SET_STREET(state, street) {
      state.street = street;
    },
    SET_HOUSE_NUMBER(state, houseNumber) {
      state.houseNumber = houseNumber;
    },
    SET_ZURS(state, zurs) {
      state.zurs = Number(zurs);
    },
    SET_API_RESPONSE(state, response) {
      state.apiResponse.status = response.status;
      state.apiResponse.value = response.value;
      state.apiResponse.message = response.message;
    },
    RESET_STATE(state) {
      const defaultState = initialState();
      Object.keys(defaultState).forEach((key) => {
        state[key] = defaultState[key];
      });
    },
  },

  actions: {
    async saveAddress({ commit, state }) {
      try {
        const response = await apiBackend.checkZuersZone({
          place: state.place,
          postalCode: state.postalCode,
          street: state.street,
          houseNumber: state.houseNumber
        });

        commit('SET_API_RESPONSE', {
          status: response.status,
          value: response.data,
          message: 'Address saved successfully'
        });
        commit('SET_ZURS', response.data.value);
      } catch (error) {
        commit('SET_API_RESPONSE', {
          status: 'error',
          value: null,
          message: error.response.data.message || 'Error saving address'
        });
      }
    }
  },

  getters: {
    fullAddress: (state) => `${state.place}, ${state.postalCode}, ${state.street} ${state.houseNumber}`,
    apiResponse: (state) => state.apiResponse,
    getZurs: (state) => state.zurs,
    getApiResponseStatus: (state) => state.apiResponse.status,
  }
};
