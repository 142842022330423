import Vue from 'vue';
import Vuex from 'vuex';
import tabsModule from '@/store/modules/tabs-module';
import insuranceModule from '@/store/modules/insurance-module';
// eslint-disable-next-line import/no-cycle
import sidModule from '@/store/modules/sid-module';
import authModule from '@/store/modules/auth-module';
import zuersZoneModule from '@/store/modules/zuersZone';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    tabsModule,
    insuranceModule,
    sidModule,
    authModule,
    zuersZoneModule
  },
});
