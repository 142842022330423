






























































































import Vue from 'vue';

import { LOGO, PHONE_LOGO, MAIL_LOGO } from '@/config/images';
import { getUser, logout } from '@/helper/handle-auth';
import { WEBSITE_URL } from '@/constant/constants';
import apiBackend from '@/service/api-insurance';
import { USER_ROLE_SUPER_ADMIN } from '@/constant/auth-constants';
// import { AuthResponse } from '@/model/auth';

export default Vue.extend({
  name: 'AppHeader',
  props: ['id', 'LoggedIn', 'customerData'],
  watch: {
    $route(to, from) {
      this.user = getUser();
      this.$store.commit('authModule/setUser', this.user);

      if (this.user !== null && this.user !== undefined) {
        this.$emit('LoggedIn', true);
      }
    },
  },
  data() {
    return {
      LOGO,
      PHONE_LOGO,
      MAIL_LOGO,
      // isLoggedIn: true,
      showPopover: false,
      showCollapseMenu: false,
      user: getUser(),
      WEBSITE_URL,
    };
  },
  mounted() {
    this.user = getUser();

    this.$store.commit('authModule/setUser', this.user);

    if (this.user) {
      this.$emit('LoggedIn', true);
    }
  },
  methods: {
    scrollUp() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    connectToOldSystem() {
      apiBackend.connectToOldSystem().then((response) => {
        window.open(response.data);
      });
    },
    getSIDFromStore(): string | null{
      return this.$store.getters['sidModule/getSID'];
    },
    getIDFromStore(): number | null{
      return this.$store.getters['sidModule/getInsuranceId'];
    },
    logout() {
      logout();
      this.$store.commit('authModule/setUser', null);

      this.$emit('LoggedIn', false);

      this.$router.push('/');
    },
  },
  computed: {
    SID(): string | null {
      return this.getSIDFromStore();
    },
    IDInsurance(): number | null {
      return this.getIDFromStore();
    },
    roles(): string[] {
      return this.user?.roles || [];
    },
    isSuperAdmin(): boolean {
      return this.roles.indexOf(USER_ROLE_SUPER_ADMIN) > -1;
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
});
