export const WEBSITE_URL = process.env.VUE_APP_WEBSITE_URL;
export const WEBSITE_PUBLIC_URL = process.env.VUE_APP_WEBSITE_PUBLIC_URL;
export const RECHENKERN_URL = process.env.VUE_APP_RECHENKERN_URL;
export const PUSHER_KEY = '1047a486123a2b9c5591';
export const PUSHER_CLUSTER = 'eu';
export const zipApiUid = 'tkhorukdev@gmail.com';
export const zipApiKey = '043f7774-d6f6-419c-8c03-06a09c74127b';

export const AXIOS_DEFAULT = {
  baseURL: WEBSITE_URL,
  publicURL: WEBSITE_PUBLIC_URL,
  PUSHER_KEY,
  PUSHER_CLUSTER,
};

export const CASE_STATUS = {
  DRAFT: 0,
  OFFER: 1,
  OFFER_REQUEST: 11,
  OFFER_REQUEST_READY: 12,
  SUGGESTION: 2,
  CONTRACT: 3,
  CONTRACT_NO_SIGNATURE: 4,
};

export const INDIVIDUAL_INSURANCE_KEY = 'INDIVIDUAL_INSURANCE';
export const CONSTRUCTION_WORK_INSURANCE_KEY = 'CONSTRUCTION_WORK_INSURANCE';
export const CONSTRUCTION_WORK_ABU_INSURANCE_KEY = 'CONSTRUCTION_WORK_ABU_INSURANCE';
export const ELEKTRONIK_INSURANCE_KEY = 'ELEKTRONIK_INSURANCE';
export const MACHINES_POWER_PLANTS_INSURANCE_KEY = 'MACHINES_POWER_PLANTS_INSURANCE';
export const WINDENERGIEANLAGEN_INSURANCE_KEY = 'WINDENERGIEANLAGEN_INSURANCE';
export const BIOGAS_INSURANCE_KEY = 'BIOGAS_INSURANCE';
export const ASSEMBLY_INSURANCE_KEY = 'ASSEMBLY_INSURANCE';
export const PHOTOVOLTAIK_INSURANCE = 'PHOTOVOLTAIK_INSURANCE';

export const productNames = {
  [INDIVIDUAL_INSURANCE_KEY]: 'Maschine',
  [MACHINES_POWER_PLANTS_INSURANCE_KEY]: 'Maschine',
  [CONSTRUCTION_WORK_INSURANCE_KEY]: 'Bauleistung',
  [CONSTRUCTION_WORK_ABU_INSURANCE_KEY]: 'Bauleistung',
  [ELEKTRONIK_INSURANCE_KEY]: 'Elektronik',
  [WINDENERGIEANLAGEN_INSURANCE_KEY]: 'Windenergieanlagen',
  [BIOGAS_INSURANCE_KEY]: 'Biogas',
  [ASSEMBLY_INSURANCE_KEY]: 'Montageversicherung',
  [PHOTOVOLTAIK_INSURANCE]: 'Photovoltaik',
};

export const MACHINE_FAHRBAR = 'MaschineFahrbar';
export const MACHINE_STATIONAR = 'MaschineStationar';
export const MACHINE_KRAFTWERKE = 'Kraftwerke';

export const MACHINE_STATIC_VALUE = 'Stationär-AMB';
export const MACHINE_FULL_COVERAGE = 'Volldeckung';

export const machineTypes = [
  {
    name: 'Maschine fahrbar',
    value: MACHINE_FAHRBAR,
  },
  {
    name: 'Maschine stationär',
    value: MACHINE_STATIONAR,
  },
  {
    name: 'Kraftwerke',
    value: MACHINE_KRAFTWERKE,
  }
];

export type pramienSelbstbehaltsvariantenItemType = {
  isSelected: boolean;
  SB: null | number;
  SBpercent: null | number;
  SBmin: null | number;
  SBmax: null | number;
  SBRabattfaktor: string;
  TarifpramiensatzAktuellNetto: string;
  Tarifpramiensatz: string;
  TarifpramieNetto: string;
}

export const riskGroups = [
  {
    group: 121,
    title: 'Heizungs- und Warmwasseranlage (Nah- und Fernwärme)'
  },
  {
    group: 121,
    title: 'Dampferzeugung (Kessel) / Dampfkraftwerk komplett (sofern nicht aufteilbar)'
  },
  {
    group: 121,
    title: 'Dampfturbinengruppen'
  },
  {
    group: 108,
    title: 'Wasserkraftanlagen (nur Maschinenteil)'
  },
  {
    group: 124,
    title: 'Komplettes Kraftwerk, Kessel, Dampf und Gasturbine (GuD kpl.)'
  },
  {
    group: 123,
    title: 'Gasturbinen (einzeln, ohne BHKW)'
  },
  {
    group: 127,
    title: 'Stromaggregat Otto/Diesel'
  },
  {
    group: 127,
    title: 'Blockheizkraftwerk (BHKW)'
  },
  {
    group: 128,
    title: 'Biogasanlagen (einschl. aller Nebenanlagen, auch Nahwärmeleitungen)'
  },
  {
    group: 123,
    title: 'Flugtriebwerk'
  },
  {
    group: 127,
    title: 'Schiffsantriebsmotor mit Getriebe'
  },
  {
    group: 127,
    title: 'Generatoren'
  },
  {
    group: 126,
    title: 'Windenergieanlage'
  },
  {
    group: 110,
    title: 'Elektromotore'
  },
  {
    group: 101,
    title: 'Elektronik Sende-/Empfangsgeräte'
  },
  {
    group: 102,
    title: 'Elektrische Versorgung (Schalt-, Mess-, Regel-)/Haustechnik'
  },
  {
    group: 102,
    title: 'Elektrische Versorgung (Akkubatterien, Erdkabel)'
  },
  {
    group: 102,
    title: 'Elektrische Versorgung (Transformatoren)'
  },
  {
    group: 125,
    title: 'Photovoltaikanlage (inkl. Wechselrichter, Trafo u. aller Bauteile) über 400 kWp und mit mindestens 1 Mio Euro VSu (sonst: PV-Pauschalprodukt)'
  },
  {
    group: 125,
    title: 'Sonnenkollektor (thermisch)'
  },
  {
    group: 116,
    title: 'Wasser, Abwasser, Fernwärme, Kläranlage (maschineller Teil)'
  },
  {
    group: 122,
    title: 'Gaserzeugung/-versorgung; Druckluft'
  },
  {
    group: 122,
    title: 'Kälteerzeugung und -versorgung'
  },
  {
    group: 122,
    title: 'Hilfsanlagen für Kraftanlagen'
  },
  {
    group: 101,
    title: 'EDV-Anlagen'
  },
  {
    group: 122,
    title: 'Haustechnik (Klimaanlage, Wärmepumpe)'
  },
  {
    group: 117,
    title: 'Müllbehandlung (Zerkleinerung), Kompostierung'
  },
  {
    group: 108,
    title: 'Fundamente mit Isolierplatte'
  },
  {
    group: 108,
    title: 'Transporteinrichtungen (Krane)'
  },
  {
    group: 129,
    title: 'Transporteinrichtungen (Bandförderanlagen, Becherwerke etc.)'
  },
  {
    group: 119,
    title: 'Bergbau und Torf'
  },
  {
    group: 122,
    title: 'Erdöl- / Erdgasförderung/Bohrtechnik'
  },
  {
    group: 113,
    title: 'Chemie allgemein'
  },
  {
    group: 105,
    title: 'Kunststoff, Gummi und Linoleum'
  },
  {
    group: 105,
    title: 'Leder'
  },
  {
    group: 105,
    title: 'Textil'
  },
  {
    group: 113,
    title: 'Reinigungsanstalten, Wäscherei'
  },
  {
    group: 108,
    title: 'Metallaufbereitung'
  },
  {
    group: 114,
    title: 'Gießereien und Schmelzöfen'
  },
  {
    group: 114,
    title: 'Warm- und Kaltverformung'
  },
  {
    group: 114,
    title: 'Wärmebehandlung (Öfen/Metall)'
  },
  {
    group: 108,
    title: 'Blechverarbeitungs- und Drahtziehmaschinen'
  },
  {
    group: 108,
    title: 'Schweißanlagen'
  },
  {
    group: 108,
    title: 'Spanabhebende Formung'
  },
  {
    group: 108,
    title: 'Galvanik'
  },
  {
    group: 106,
    title: 'Holz'
  },
  {
    group: 111,
    title: 'Papier-, Pappen-, Zelluloseherstellung'
  },
  {
    group: 111,
    title: 'Papierverarbeitung'
  },
  {
    group: 111,
    title: 'Graphisches Gewerbe'
  },
  {
    group: 111,
    title: 'Graphisches Gestaltungssystem'
  },
  {
    group: 103,
    title: 'Zuckerfabrik'
  },
  {
    group: 103,
    title: 'Nahrungs- und Genussmittel'
  },
  {
    group: 110,
    title: 'Krankenhäuser (Medizintechnik)'
  },
  {
    group: 108,
    title: 'Tankstellen, Waschanlagen (Bauteil über RG 131)'
  },
  {
    group: 101,
    title: 'Film, Funk, Kino'
  },
  {
    group: 129,
    title: 'Verkehrswesen (Seil- u. Gondelbahnen, Skilifte etc.; inkl. Masten+Fundamente)'
  },
  {
    group: 112,
    title: 'Lager und Umschlaganlagen'
  },
  {
    group: 109,
    title: 'Mischanlagen (einschl. Trocknungsanlagen)'
  },
  {
    group: 108,
    title: 'Hebezeuge und Transportgeräte'
  },
  {
    group: 130,
    title: 'Bagger, Raupen, Lade-/Schürfgeräte (Großgeräte auf der Baustelle)'
  },
  {
    group: 130,
    title: 'Rammen, Pfahlzieher, Bodenverdichter (Großgeräte auf der Baustelle)'
  },
  {
    group: 122,
    title: 'Brunnenbau (Bohrausrüstung)'
  },
  {
    group: 129,
    title: 'Straßenbau (Oberleitungen)'
  },
  {
    group: 129,
    title: 'Gleisoberbau'
  },
  {
    group: 122,
    title: 'Druckluft'
  },
  {
    group: 104,
    title: 'Gerüste'
  },
  {
    group: 108,
    title: 'Schwimmbagger'
  },
  {
    group: 109,
    title: 'Baustoff, Steinbruch und Glas'
  },
  {
    group: 131,
    title: 'Bauleistung / Bauteil Hochbau (Tiefbau: NUR über Bauleistungstarif)'
  },
];

export const vertragsartItems = [
  {
    value: 'ABN-Einzelrisiko',
    text: 'ABN-Einzelrisiko',
  },
  {
    value: 'ABN-Umsatzvertrag',
    text: 'ABN-Umsatzvertrag',
  },
  {
    value: 'ABN-Anmeldevertrag',
    text: 'ABN-Anmeldevertrag',
  },
  {
    value: 'ABU-Einzelrisiko',
    text: 'ABU-Einzelrisiko',
  },
  {
    value: 'ABU-Umsatzvertrag',
    text: 'ABU-Umsatzvertrag',
  },
  {
    value: 'ABU-Anmeldevertrag',
    text: 'ABU-Anmeldevertrag',
  },
  {
    value: 'Baukombi',
    text: 'Baukombi',
  }
];

export const artGeboudeItems = [
  'Wohngebäude bis 10 Stockwerke',
  'Büro-, Verwaltungs- und Geschäfts-/ Betriebsgebäude bis 10 Stockwerke',
  'Kommunal-Bauvorhaben',
  'Pensionen',
  'Gaststätten',
  'Landgasthöfe und Hotels bis 3-Sternekategori',
  'Fabrik-, Industrie- und Lagerhallen',
  'Bauernhöfe',
  'Stallungen',
  'Scheunen und Remisen',
  'oberirdische Parkhäuser',
  'Tiefgaragen',
  'Tankstellen',
  'Waschstraßen',
  'Werkstätten',
  'Altenheime / Seniorenwohnheime',
  'Wohngebäude über 10 Stockwerke',
  'Büro-, Verwaltungs- und Geschäfts-/ Betriebsgebäude über 10 Stockwerke',
  'Krankenhäuser, Kliniken, Sanatorien',
  'Theater, Konzert-, Fest- und Messehallen',
  'Mehrzweck- und Sporthallen, Hallenschwimm-, Frei- und Erlebnisbäde',
  'Landgasthöfe und Hotels über 3-Sternekategorie',
  'Banken, Versicherungen',
  'Einkaufszentren, Kühlhäuser',
  'Fabrik-, Industrie- und Lagerhallen (über 20m Spannweite je Stütze)',
  'Kirchen, Schlösser, Museen',
  'Stadion',
  'Fernsehturm',
  'Flugterminal',
  'Baulicher Teil von Heizkraftwerken und Müllverbrennungsanlagen'
];

export const artGeboudeItemsAbu = [
  'Gebäude- Rohbauten bis 10 Stockwerke / über 10 Stockwerke',
  'gemauerte/betonierte Türme, Schornsteine, Silos, Masten',
  'Stützmauern',
  'Komplett (Straßen- u. Wegebau, Kanal- und Leitungsbau)',
  'Einfache Asphaltstraßen, Wegebau, Parkplätze, gepflasterte Plätze',
  'Gemeinde- und Verbindungsstraßen (Ober- und Unterbau)',
  'Straßenbahn- und Eisenbahnbau (Ober- und Unterbau)',
  'Autobahnen / Schnellstraßen (Ober- und Unterbau)',
  'einfache Straßenbrücken (Über- und Unterführungen/ Durchlässe) bis 20m Spannweite',
  'Brücken mit Spannweiten von 20 m bis 50 m Spannweite je Feld',
  'Brücken mit Spannweiten von über 50 m Spannweite je Feld',
  'Sonderbrücken (z.B. Schrägseil-/Pylonenbrücke, Klapp-/ Drehbrücken)',
  'Kabelverlegung mittels Grabenfräse und maschineller Verlegung',
  'Kommunaler Leitungsbau (Gas, Wasser, Dampf) mit HDPE-/ Stahlrohre',
  'Kanalbau bis DN 400 / mit zum Auftrag gehörende Kleinpressung',
  'Kanalbau bis DN 1000',
  'Kanalbau über DN 1000',
  'Pipelines',
  'Kanalreparaturen im Inliner-, Partliner-, Hutlinerverfahren',
  'Durchpressungen (als alleinige Baumaßnahme oder Wertanteil > 500.000 EUR)',
  'Durchpressungen bis DN 500',
  'Durchpressungen über DN 500 bis DN 1000',
  'Durchpressungen über DN 1000',
  'Horizontalbohrung/HDD',
  'HDD  bis 500 m Länge',
  'HDD über 500m Länge',
  'eigenständige Erdwärmebohrungen bis max. 400 m Endteufe',
  'Sonstige Vertikal- und Horizontalbohrungen aller Art',
  'Kläranlagen mit maschinell-technischer Einrichtung',
  'Kläranlagen ohne maschinell-technischer Einrichtung',
  'ober- und unterirdische Behälter/ Becken, RÜB, Pumpwerke mit maschinell-technischer Einrichtung',
  'ober- und unterirdische Behälter/ Becken, RÜB, Pumpwerke ohne maschinell-technischer Einrichtung',
  'U- und S- Bahntunnel, Auto- und Zugtunnel in bergm. Bauweise',
  'U- und S- Bahntunnel, Auto- und Zugtunnel in Deckelbauweise',
  'Flussregulierungen und Hochwasserschutzdämme',
  'Flussdüker mittels Einziehen / mittels Einschwimmen',
  'Seeleitungen',
  'Hafenanlagen, See- und Flussschleusen',
  'Talsperren',
  'Wasserkraftwerke und Wehranlagen nur baulicher Teil',
  'Kleinwasserkraftwerke inkl. Stahlwasserbau und mit maschinellen und elektrotechnischen Anlagen bis Herstellungskosten 1 Mio EUR',
  'Wehranlagen inkl. Stahlwasserbau und mit maschinellen und elektrotechnischen Anlagen',
  'Golfplätze',
  'eigenständige Sport-, Fußball- und Tennisplätze',
  'Deponien',
  'Lärmschutzwälle und -wände (Alu, Holz, Glas)',
];

export const gebaudeartItems = [
  'Standard o. Eingriff in Statik',
  'Standard m. Eingriff in Statik',
  'Hochwertig o. Eingriff in Statik',
  'Hochwertig m. Eingriff in Statik',
  // 'Erstes Risiko',
];

export const gebaudeartAltbauItems = [
  'Altbau incl. Ausbau Standard',
  'Altbau incl. Ausbau hochwertig',
  'Rohbauzustand nach Entkernung',
  // 'Erstes Risiko'
];

export const inclusionOfClientDamages = [
  'Gebäude-Rohbau',
  'Gebäude- Rohbau inkl. Gewässer',
  'Tiefbau',
  'Tiefbau inkl. Gewässer',
];

export const photovoltaicsTypes = [
  {
    label: 'Dach-bzw. Fassadenanlagen mit einer Leistung bis 10 kWp',
    value: '< 10 kWp'
  },
  {
    label: 'Dach- bzw. Fassadenanlagen über 10 kWp (bis zu einem Wert von max. 1 Mio. EUR)',
    value: '> 10 kWp'
  }
];

export const photovoltaicsOperatingMods = [
  { label: 'Andere Anlagen zur Energieerzeugung und -Verteilung(Heizkraftwerke, Brennstoffzellen, Wasserstoffanlagen, Biogasanlagen)', value: 12100 },
  { label: 'Asylbewerber-, Obdachlosenunterkunft', value: 12220 },
  { label: 'Bar, Tanzlokal, Diskothek', value: 12240 },
  { label: 'Chemische Anlagen aller Art', value: 12250 },
  { label: 'Deponie', value: 12270 },
  { label: 'Gewinnung, Be- und Weiterverarbeitung, Lagerung von fossilen Energieträgern (z.B.Kohle)', value: 12271 },
  { label: 'Gummi- (u.a. Reifen) Schaumstoff- , Kunststoff - Herstellung, Be- und Verarbeitung sowie Lagerung', value: 13330 },
  { label: 'Halbstoff-, Papier-, Karton-, Pappeherstellung', value: 13340 },
  { label: 'Holzpelletherstellung', value: 13450 },
  { label: 'Holzspan-, Holzfaserplatten-, Spanplattenherstellung', value: 13460 },
  { label: 'Intensivtierhaltung (Geflügelfarm, großer Schweinemastbetrieb usw.)', value: 13810 },
  { label: 'Landwirtschaft', value: 13910 },
  { label: 'Müllverbrennungsanlage', value: 13930 },
  { label: 'Recycling (von Stoffen aller Art, wie Altpapier, Kunststoff, Textil, Batterien Schrott etc.)', value: 13962 },
  { label: 'Sägewerk', value: 15420 },
  { label: 'Sonderlager / Logistikzentrum', value: 16100 },
  { label: 'Torfbetrieb', value: 16120 },
  { label: 'Vergnügungsbetrieb, Eroscenter, Massagesalon, Stundenhotel', value: 17750 },
  { label: 'Keine', value: 12099 },
];
